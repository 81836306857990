@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    box-sizing: border-box;
}
.contain {
    min-height: 100vh;
    position: relative;
    font-family: 'Noto Sans KR', sans-serif;
}

.top_tap .tapicon {
    font-size: 20px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 7px;
    transform: translate(0, -50%);
}

.top_taps.active {
    font-size: 16px;
    font-weight: 700;
    color: brown;
}

.pdfTop {
    justify-items: center;
    display: flex;
    align-items: center;
}
.navi {
    color: #000046 !important;
}
.green {
    color: #00685a;
}

.xbtnstyle {
    outline: none;
    border: none;
    cursor: pointer;
    color: #fff;
    background-color: #b32b2b;
    padding: 5px;
    border-radius: 10px;
    text-align: center;
    text-align: center;
}
.opacitynone {
    width: 5px;
    opacity: 0;
    position: absolute;
    top: 0;
}
.f16 {
    font-size: 16px;
}
.blackfont {
    color: #000;
}
.f18 {
    font-size: 18px !important;
}
.f20 {
    font-size: 20px !important;
}
.f22 {
    font-size: 22px !important;
}
.f14 {
    font-size: 14px !important;
}
.f12 {
    font-size: 12px !important;
}
.bold {
    font-weight: 900;
}
/* 넓이 관련 */

.w100 {
    width: 100% !important;
}
.w110 {
    width: 110px;
}
.w50px {
    width: 50px;
    text-align: center;
}
.block {
    display: block !important;
}
.w60 {
    width: 60%;
}
.w40 {
    width: 40%;
}

.w_200px {
    width: calc(100% - 200px);
}
.w100px {
    width: 100px;
}
.w_100px {
    width: calc(100% - 100px);
}
.w50 {
    width: calc(50% - 10px) !important;
}
.w50_ {
    width: 50% !important;
}
.w90_ {
    width: 90% !important;
}
.w80_ {
    width: 80% !important;
}
.br0 {
    border-right: unset !important;
}
.bl0 {
    border-left: unset !important;
}
.w200px {
    width: 200px !important;
}
.w_200 {
    width: calc(100% - 200px);
}

.w100_ {
    width: 200% !important;
}
.w20_ {
    width: 20% !important;
}
.w15px {
    width: 15px !important;
}
.w33 {
    width: calc(33.33333% - 10px) !important;
}
.w25 {
    width: calc(25% - 10px) !important;
}
.w20.w {
    width: 20% !important;
}
.w30.w {
    width: 30% !important;
}
.w25.w {
    width: 25% !important;
}
.w15.w {
    width: 15% !important;
}
.w35.w {
    width: 35% !important;
}
.p55 {
    padding: 5px;
}
.tw16 {
    width: 16%;
}
.tw8 {
    width: 8%;
}
.tw10 {
    width: 10%;
}
.tw12 {
    width: 12%;
}
.tw14 {
    width: 14%;
}
.m0 {
    margin: unset !important;
}
.updatetimes {
    font-size: 14px;
    color: #757575;
}
.swal2-popup {
    justify-items: center !important;
}
.swal2-icon {
    margin-top: 20px;
}
.w1480 {
    width: 1480px;
    margin: auto;
    position: relative;
}
.w140 {
    width: 140px;
    display: block;
}
.w120 {
    width: 120px !important;
}
.ohidden {
    overflow: hidden;
}
.none {
    display: none;
}
img {
    width: 100%;
    height: 100%;
}
.relative {
    position: relative;
}
.flex {
    display: flex;
}
.top100 {
    position: absolute;
    bottom: 20%;
}
.fontB {
    font-weight: 900;
}
.colorRed {
    color: red;
}
.colorR {
    color: #b32b2b;
}
.colorH {
    color: #fff !important;
}
.flex.end {
    display: flex;
    align-items: flex-end;
}
.flex.wrap {
    flex-wrap: wrap;
}
.flex.b {
    align-items: flex-end;
    justify-content: space-between;
}
.flex.c {
    justify-content: center;
}
.flex.a {
    align-items: center;
}
.flex.j {
    justify-content: space-between;
}
.flex.r {
    justify-content: right;
}
.flex.alienC {
    justify-content: center;
}
.flex.sb {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flex.fs {
    display: flex;
    align-items: flex-start;
}
.flex.js {
    display: flex;
    justify-content: flex-start;
}
.flex.center {
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.flex.centerj {
    justify-content: center;
    flex-direction: column;
}
.flex.ac {
    align-items: center;
    flex-direction: column;
}
.flex.centerl {
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.flex.jc {
    justify-content: center;
    flex-direction: column;
}
.flex .radiolabel {
    margin-right: 10px;
}
.textc {
    text-align: center;
}
.middletext {
    padding: 5px 0;
    font-size: 16px;
    font-weight: 900;
    color: #000;
}
.lb {
    background-color: #000046;
}
.wf {
    color: #fff;
}
.bigNumger {
    font-size: 24px;
    color: #000031;
}
.minititle {
    color: #16164b;
}
.miniTop {
    color: #fff;
    text-align: right;
    font-size: 12px;
    padding-right: 40px;
}
.userinfoBox {
    height: calc(100% - 50px);
    flex-direction: column;
}
.subtext {
    font-size: 12px;
    color: #00685a;
}
.innerbox {
    margin-top: 20px;
    font-size: 15px;
    border: 1px solid rgb(19, 39, 99);
    background-color: rgb(255, 255, 255);
    padding: 16px 20px;
    border-radius: 5px;
    position: relative;
}
.innerbox h1 {
    color: #000;
    font-weight: 600;
    margin-bottom: 10px;
}
.innerbox p {
    color: rgb(119, 119, 119);
    font-size: 14px;
    margin-bottom: 5px;
}

.greyb {
    background-color: #f5f5f5;
}
.p0 {
    padding: 0px 0px !important;
}
.p3_3 {
    padding: 3px !important;
}
.widthunset {
    width: unset !important;
}
.p5 {
    padding: 5px 0;
}
.pt10 {
    padding-top: 10px;
}
.pb10 {
    padding-bottom: 10px;
}
.p10 {
    padding: 10px 10px !important;
}
.p20 {
    padding: 0px 20px;
}
.p20tb {
    padding: 20px 0px;
}
.p40tb {
    padding: 40px 0px;
}
.p15tb {
    padding: 15px 0px;
}
.p2020 {
    padding: 20px 20px;
}
.pb30 {
    padding-bottom: 30px;
}
.p4040 {
    padding: 40px 40px !important;
}
.min300 {
    min-height: 300px;
}
.mg15 {
    margin: 15px;
}
.mr .mg20 {
    margin: 20px;
}
.mr20 {
    margin-right: 20px;
}

.m .mr40 {
    margin-right: 40px;
}
.mr55 {
    margin-right: 55px;
}
.overfit {
    width: 100%;
    height: 100%;
}
.h100 {
    height: 100%;
}
.h100vh {
    height: 100vh;
}

.outsourcing {
    background-color: #fff;
    min-height: 50vh;
    position: relative;
}
.outsourcing .outimgwrap {
    width: 350px;
}
.outimgwrap h1 {
    margin-top: 30px;
    font-size: 28px;
    text-align: center;
    font-weight: 700;
}
.h70vh {
    height: 70vh;
}
.backbg {
    position: absolute;
    top: 0;
    z-index: -1;
}

#swal2-html-container {
    font-size: 14px !important;
}
.midcircle {
    height: 30px;
    background-color: #000046;
    width: 30px;
    border-radius: 30px;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.estimatebox .table_container ul li span:first-child {
    width: 80px;
    display: inline-block;
}
.term {
    display: inline-block;
    width: 20px !important;
}
.estimatebox .emptyBox {
    min-height: 189px;
}
.estiHead {
    font-size: 20px;
    text-align: center;
    font-weight: 900;
    border-bottom: 2px solid #1baacc;
    border-top: 2px solid #1baacc;
    padding-bottom: 10px;
    padding-top: 10px;
}
.esti_head {
    padding: 10px;
    font-size: 18px;
    font-weight: 700;
}

.pointer {
    cursor: pointer;
}
.underline:hover {
    border-bottom: 1px solid #aaa;
}
.mb0 {
    margin-bottom: 0px !important;
}
.mt0 {
    margin-top: 0px !important;
}

.mt-10 {
    margin-top: -10px !important;
}

.mr10 {
    margin-right: 10px !important;
}

.ml5 {
    margin-left: 5px;
}
.ml10 {
    margin-left: 10px;
}

.ml20 {
    margin-left: 20px;
}

.mr5 {
    margin-right: 5px !important;
}
.mr40 {
    margin-right: 40px;
}
.mb5 {
    margin-bottom: 5px;
}
.mb10 {
    margin-bottom: 10px;
}
.mb25 {
    margin-bottom: 25px;
}
.w60px {
    width: 60px !important;
}
.mt10 {
    margin-top: 10px;
}
.mt15 {
    margin-top: 15px;
}
.mt20 {
    margin-top: 20px;
}
.mt30 {
    margin-top: 30px !important;
}
.mt45 {
    margin-top: 45px !important;
}
.mt40 {
    margin-top: 40px !important;
}
.mt50 {
    margin-top: 50px !important;
}
.mb20 {
    margin-bottom: 20px !important;
}
.mb40 {
    margin-bottom: 40px;
}
.mb80 {
    margin-bottom: 80px;
}
.main {
    overflow: hidden;
    position: relative;
}
.border {
    border: 1px solid #757575;
}
.header .btnstyle {
    line-height: 50px;
}
.headbg {
    background-color: #000;
    height: 200px;
    width: 100%;
    position: fixed;
    top: 98px;
    left: 0;
    z-index: 2;
}
.btnstyle {
    width: 100px;
    height: 30px;
    border: unset;
    text-align: center;
    outline: unset;
}

/* alert수정 */
.optionalert .swal2-actions {
    grid-column: 1;
    grid-row: 4; /* 맨 밑의 행으로 변경 */
    align-self: center;
    justify-self: center;
    margin-top: 8px;
}
.optionalert a {
    font-size: 20px;
    margin: 0;
    font-weight: 900;
    color: #00009b;
}
.optionalert .swal2-footer {
    border-bottom: 1px solid #eee;
    padding: 10px;
}

/* 로그인페이지 */
.passerror {
    font-size: 12px;
    color: #b32b2b;
}
.pass_success {
    font-size: 12px;
    color: #0d3a9c;
    text-align: left;
}

/* subhead */
.subhead {
    width: 100%;
    height: 400px;
    position: relative;
    overflow: hidden;
}
.subhead .subheadimg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.subheadT {
    font-size: 40px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
}
.subheadText {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.subheadTb {
    text-align: center;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
}
.info-table {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    border-collapse: collapse;
}

.info-row {
    width: 100%;
}

.info-cell {
    padding: 5px;
    border: 1px solid #ddd;
    vertical-align: top;
    display: flex;
}
.info-cell.specials {
    justify-content: unset;
}

.info-cell span {
    width: 100px;
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.info-cell p,
.info-cell div {
    margin: 0;
    display: block;
    text-align: left;
    width: calc(100% - 110px);
}
/* 배경 */
/* .contain{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #0b255b;
    z-index: -1;
} */
.project {
    padding-bottom: 30px;
}
/* 푸터 */
.footer {
    height: 140px;
    background-color: #000031;
    box-sizing: border-box;
}
.footerbox {
    justify-content: space-between;
}

.mainpg {
    position: relative;
}
.heightAuto {
    height: auto !important;
}
.footerbox ul li,
.footerbox ul li a {
    color: #fff;
}

.subnav_wrap {
    width: 100%;
    background-color: #000046;
    position: absolute;

    display: flex;
    justify-content: center;
    bottom: 0;
}
.subnav_box {
    display: flex;
    justify-content: center;
    position: relative;
}
.subnav_box div {
    margin-right: 20px;
}
.subnav_box div.active {
    background-color: #fff;
    color: #000;
}
.subnav_box div.active a {
    color: #000;
}
.subnav_box div a {
    font-weight: 700;
    padding: 20px 0;
    color: #fff;
    text-align: center;
    cursor: pointer;
    display: block;
    min-width: 200px;
    white-space: nowrap;
}
.subnav_box.ml370 {
    justify-content: left;
}
.subnav_box.ml370 div:first-child {
    margin-left: 370px;
}
.subnav_box div:last-child {
    margin-right: 0;
}
/* 헤더 */
.header {
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 100;
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.5s ease;
}

.header.fix {
    background-color: #000031;
    position: fixed;
    top: -1px;
    left: 0;
    padding: 0px 20px;
    height: 100px;
}
.header.hoverd {
    background-color: #000031;
}
.navi {
    display: flex;
}
.navi > li {
    margin-right: 10px;
    font-size: 15px;
    width: 120px;
    height: 50px;
    text-align: center;
}
.navi > li:hover a {
    border-bottom: 1px solid #fff;
}
.navi > li.active a {
    color: #fff !important;
    border-bottom: 1px solid #fff;
}
.navi > li:last-child {
    margin-right: 0px;
}

.header .navi li a {
    color: #fff;
    display: block;
    height: 100%;
    line-height: 60px;
    font-weight: bold;
}

.logo {
    width: 160px;
    height: 55px;
    display: block;
    cursor: pointer;
}
.mainback > img,
.companyback > img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
}
.backback {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.backbacksub {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.mainback {
    width: 100%;
    height: 100%;
    position: relative;
}
.mainb {
    background-color: #00000073;
}
.mainbb {
    background-color: #00000093;
}
.companyb {
    background-color: #000;
}
.locationb {
    background-color: #fff;
}
.mainpback {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: #0b255b;
}
.boardimg {
    position: absolute;
    right: 10%;
    width: 30%;
    height: auto;
    top: 0;
}
.mainbackT {
    position: absolute;
}
.mainbackT.r {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
}
.mainbackT.cr {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
}

.xscroll-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* This is needed to prevent the image from "jittering" partway through the transition */
    overflow: hidden;
}

.img-container {
    width: 50%;
    display: flex;
    align-items: center;
    position: relative;
}

.bottom-shadow {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
}
.bottom-shadow.left {
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0));
}
.bottom-shadow.right {
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 60%);
}
.xscrollimg {
    z-index: 1;
}
.top-shadow {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
}
.top-shadow.left {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 25%);
}
.top-shadow.right {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 60%);
}
.mainbackT.r p {
    text-align: right;
}
.mainbackT p {
    font-size: 40px;
    font-weight: 900;
    color: #dbdbdb;
}
.mainbackT p.big {
    font-size: 60px;
    color: #fff;
    line-height: 63px;
}
.mainbackT p.normal {
    font-size: 45px;
    color: #fff;
    line-height: 63px;
    margin-top: 80px;
}
.subT {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 3px solid #fff;
}
.mainbackT.br {
    right: 150px;
    bottom: 150px;
    font-size: 24px;
}
.mainbackT p.small {
    font-size: 18px;
    color: #fff;
}
.mainbackT p.middle {
    font-size: 30px;
    color: #757575;
}
.mainbackT p.middle span {
    margin-right: 20px;
}
.mainbackT p.middle span:last-child {
    margin-right: 0;
}
.mainbackT p.middle span.w {
    font-size: 44px;
    color: #fff;
}
.mainbackmiddle {
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.mainbackmiddle h5 {
    color: #fff;
    font-size: 26px;
    padding: 5px 0;
    font-weight: 700;
    text-align: center;
}

.mainbackT.subT {
    top: 150px;
    left: 0px;
    transform: translate(0%, 0%);
}
.mainbackT.subTbottom {
    top: 250px;
    left: 0px;
    transform: translate(0%, 0);
}

.maintech {
    position: absolute;
    top: 350px;
    left: 0px;

    width: 100%;

    height: calc(100vh - 470px);
    display: flex;
    justify-content: space-between;
}
.imgmain {
    position: absolute;
    top: 290px;
    left: 50%;
    transform: translate(-50%, 0%);
    width: calc(66% - 40px);
    height: calc(100vh - 340px);
    display: flex;
    justify-content: space-between;
}
.maintechbox {
    height: 100%;
    padding: 20px 20px;
    border-left: 1px solid #757575;
    width: calc(33.33% - 10px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.maintechbox:last-child {
    border-right: 1px solid #757575;
}
.maintechinbox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.maintechinbox > img {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
}
.maintechinbox h1 {
    font-size: 22px;
    color: #fff;
}
.maintechinbox h2 {
    font-size: 18px;
    color: #fff;
}
.maintechinbox p {
    font-size: 16px;
    color: #d9d9d9;
}
.animated {
    height: 100%;
}
/* 슬라이드 */
.full-page-controls button:nth-child(1) {
    display: none;
}

.full-page-controls button:nth-last-child(1) {
    display: none;
}

.full-page-controls button {
    background-color: white;
    border: 1px solid black;
    width: 5px;
    height: 20px;
    margin: 0 5px;
    font-size: 0px;
    cursor: pointer;
}

.full-page-controls {
    flex-direction: column;
    position: fixed;
    display: flex;
    justify-content: center;
    right: 0px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1000;
}

.full-page-controls button:disabled {
    background-color: black;
    border: 1px solid white;
}
/* 프로젝트 */
.project_wrap {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}

.project_box {
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-right: 20px;
    cursor: pointer;
    width: calc(50% - 20px);
    padding: 10px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.project_box:nth-child(2n) {
    margin-right: 0px;
}
.project_thumb {
    width: 100%;
    height: 100%;
}

.project_title {
    width: 100%;
    font-size: 26px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 20px;
}

.project_subT span {
    width: 80px;
    line-height: 16px;
    font-weight: 900;
    padding-right: 5px;
    margin-right: 5px;
    border-right: 2px solid #000;
}

.project_subT div {
    width: calc(100% - 80px);
}
.project_cir {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: 1px solid #000;
    margin-right: 10px;
}
.project_cir.active {
    background-color: #0b255b;
}
.projectAdd_inputbox {
    font-size: 16px;
    padding: 12px;
    border: none;
    border-radius: 30px;
    background-color: #f0f0f0;
    width: 100%;
    outline: none;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.projectAdd_inputbox.heightover {
    height: 200px;
}

.projectAdd_inputbox:focus {
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}
.minitext {
    position: absolute;
    top: 10px;
    right: -80px;
}

.width_100s {
    width: calc(100% - 100px) !important;
}
.project_input {
    width: 100%;
    border: 1px solid #d9d9d9;
    background-color: unset;
    outline: unset;
    height: 50px;
    padding: 10px;
    margin-top: 10px;
}
textarea.project_input {
    height: unset;
}
.radiolabel {
    padding: 5px;
    font-size: 15px;
}
.project_left {
    width: 240px;
    padding: 20px;
}
/* 오시는길 */
.tablewrap {
    width: 100%;
    max-width: 1480px; /* Adjust the max-width as needed */
    margin: 20px auto;
    border: 2px solid #3498db;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.tablediv {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 15px;
    border-bottom: 2px solid #3498db;
    transition: background-color 0.3s ease-in-out;
}

.tablediv:hover {
    background-color: #f5f5f5;
}
.lilabel {
    display: flex;
    align-items: center;
}
.tableT {
    flex: auto;
    font-size: 18px;
    font-weight: 600;
    color: #3498db;
}

.tableD {
    margin-left: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #555;
}
.myproject_left .myprojectli {
    padding: 5px;
    cursor: pointer;
}
.myproject_left .myprojectli:hover {
    background-color: #949494;
    color: #fff;
}
.project_right {
    position: relative;
    padding-bottom: 100px;
    width: calc(100% - 240px);
}
.myproject_right {
    position: relative;
    width: 100%;
    min-height: 400px;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
    padding: 20px;
}
.myproject_right .btnstyle {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
}
.project__wrap {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}
.pro_box {
    width: 100%;
    height: fit-content;
    padding: 20px 20px;
    border-top: 1px solid #bbbbbb;
}
.pro_box_in {
    background-color: #a3a19c1f;
    border-color: #878b8f;
    border-radius: 30px; /* 모서리를 둥글게 하지 않음 */
    padding: 20px;
    width: 100%;
    height: 100%;
}
.navi_round_tag {
    padding: 6px 16px;
    font-weight: bold;
    background-color: #344380;
    color: #fff;
    border: 0;
    border-radius: 50px;
    width: 160px;
    height: 38px;
    text-align: center;
}
.w_round_tag_b {
    width: 160px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.w_round_tag_b > span {
    width: 120px;
    height: 45px;
    border: 2px solid #0821478a;
    border-radius: 10px;
    color: #000;
    line-height: 41px;
    text-align: center;
    background-color: #fff;
}
.w_round_tag > span {
    text-align: center;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 14px;
    border: 2px solid #ccc;
    background-color: #ffffffbd;
    color: #333;
}

.box_title a {
    font-size: 28px;
    font-weight: 700;
    color: #0f0f0f;
    position: relative;
}
.box_title a::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 95%;
    height: 2px;
    background-color: #9bd7568a; /*#98a793;*/
    padding-left: 20px;
    transform: scaleX(0);
    transform-origin: left;
    z-index: 100;
    transition: transform 0.35s ease-in-out;
}

.box_title a:hover::before {
    transform: scaleX(1);
}

.pro_box_in > div {
    margin-bottom: 15px;
}
.pro_box_in > div:last-child {
    margin: 0;
}
.box_width_tag {
    padding: 16px 20px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}
.box_width_tag span {
    font-weight: 700;
    font-size: 16px;
}
.box_width_tag.blue {
    background-color: #4198e928;
}
.pro_box.s {
    height: 100px;
}
.project__wrap .pro_box.li:hover {
    background-color: #ebebeb;
}
/* 회사소개 */
.divbox {
    margin-top: 150px;
}
.estimatebox ul > .companyT {
    width: 100%;
    height: 200px;
    overflow: hidden;
    margin-left: 30px;
    z-index: 1;
}
.pr {
    padding-right: 10px;
    border-right: 2px solid #1baacc;
}
.companyback {
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.companyT {
    width: 100%;
    height: 180px;
    overflow: hidden;
    margin-left: 30px;
    z-index: 1;
}

.companyT p {
    font-size: 30px;
    font-weight: 900;
    color: #e9e9e9;
}
.companyT p.big span {
    font-size: 46px;
    color: #fff;
    text-shadow: 2px 2px 2px #000046;
}
.companyT p.big {
    font-size: 40px;
}
.xscroll-container,
.img-container {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: space-between;
}
.xscroll-container .copy {
    width: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    padding: 30px;
    text-align: left;
}
.img-container {
    width: 50%;
}
.img-inner {
    width: 100%;
    height: 100%;
}
.container {
    position: relative;
}

/* 연혁 */
.historyT {
    font-size: 46px;
    color: #fff;
    font-weight: 900;
    padding: 40px 0 20px;
    border-bottom: 3px solid #fff;
}
.historysT {
    font-size: 32px;
    color: #fff;
    font-weight: 900;
    padding: 20px 0 20px;
}
.historycon {
    display: flex;
}
.historys {
    color: #fff;
    font-size: 18px;
    font-weight: 900;
}

.projectbtn {
    display: block;
    padding: 10px 20px;
    color: #fff;
    background-color: #042d52;
    border-radius: 10px;
    text-align: center;
    width: calc(100% - 64px);
    position: absolute;
    bottom: 24px;
    left: 32px;
}
.projectbtns {
    cursor: pointer;
    display: block;
    padding: 10px 20px;
    color: #fff;
    background-color: #042d52;
    border-radius: 10px;
    text-align: center;
    width: 200px;
}

.projectbtn a {
    text-align: center;
    display: block;
    cursor: pointer;
    color: #fff;
    width: 200px;
    font-size: 20px;
    border: 1px solid #fff;
    padding: 10px;
}
.projectbtn.b a {
    border: 1px solid #000;
    color: #000;
}

/* 프로젝트 등록 */
.add_left {
    width: 240px;
    position: relative;
    max-height: calc(100vh - 150px);
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.add_left.wide {
    width: 280px;
    max-height: calc(100vh - 360px);
}
.add_left.bigwide {
    width: 480px;
    max-height: calc(100vh - 360px);
}
.add_left.fixed {
    position: fixed;
    top: 110px;
    left: 0px;
    height: inherit;
}
.add_left.fixed.big {
    position: fixed;
    top: 210px;
    left: 0px;
}

.add_right_wrap.bigwide {
    width: calc(100% - 500px) !important;
}
.add_right_box {
    width: 100%;
}
.add_right_box.scroll {
    overflow-y: scroll;
}
.projectnav p,
.projectnavs p {
    margin-bottom: 20px;
    color: #757575;
}

.projectnavs p {
    cursor: pointer;
}
.projectnav p.active,
.projectnavs p.active {
    color: #000;
    font-weight: bold;
}
.acvtives {
    color: #000;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease;
}

.acvtives:hover {
    color: #3498db; /* A nice shade of blue for the hover effect */
    text-decoration: underline;
}

.projectnav.scrolls,
.projectnavss.scrolls {
    overflow-y: scroll;
    justify-content: unset;
    align-items: unset;
    height: calc(100vh - 550px);
    padding: 0 5px;
}
.projectnavss {
    overflow-y: scroll;
    height: calc(100vh - 550px);
}
.scrollbox.scrolls {
    overflow-y: scroll;
    padding: 10px;
}

.addproject {
    position: relative;
}
.addproject.fix {
    top: 160px;
}
.add_right_wrap {
    position: relative;
    width: calc(100% - 370px);
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.add_right_wrap.full {
    width: 100% !important;
    box-shadow: unset;
}

.add_left h1 {
    margin-bottom: 30px;
    font-weight: 900;
}
.add_right_wrap.ab {
    position: absolute !important;
    right: 0;
}
.right_top_s {
    /* 확인 삭제  */
    display: none;
    font-size: 24px;
    font-weight: 900;
    padding-bottom: 30px;
}
.right_top {
    padding: 30px;
    font-size: 24px;
    font-weight: 700;
    border-bottom: 1px solid #757575;
}

.right_body,
.right_bottom {
    padding: 30px;
}

.right_body_wrap h1 {
    font-weight: bold;
    font-size: 16px;
}

.pro_title {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    padding: 5px 0;
}
.pro_title:hover {
    border-bottom: 1px solid #000;
}
.pro_title a {
    color: #000;
}

.cates span {
    font-size: 16px;
    font-weight: 700;
}
.catewrap span {
    margin-right: 5px;
    padding: 6px 8px;
    border-radius: 3px;
    font-size: 14px;
    border: 1px solid #e5e5e5;
}

.pro_subtext {
    margin-top: 15px;
    font-size: 14px;
    color: #949494;
    white-space: nowrap;
    height: 64px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.pro_updatetime {
    font-size: 14px;
    color: #111111;
    line-height: 28px;
}
.projectTag {
    width: 80px;
    height: 30px;
    text-align: center;
    line-height: 30px;
}

.pro_box.s {
    border: unset;
}

/* .project__wrap .pro_box:first-child{
    border-top: unset;
} */

.bar {
    width: 1px;
    height: 14px;
    background-color: #212121;
}
.borderTop {
    border-top: 2px solid #636161;
}
.borderBottom {
    border-bottom: 2px solid #636161;
}
.left_box > li {
    padding: 5px;
}

.left_box ul li {
    padding: 5px;
}
.pro_left_box h1 {
    margin-bottom: 10px;
    height: 24px;
    line-height: 1.71;
    color: #212121;
    font-weight: 800;
}
.selectbox {
    width: 120px;
    padding: 10px;
    padding-left: 12px;
    border: 1px solid #ddd;

    border-radius: 4px;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 12px;
    color: #000;
    outline: none;
}
.selectbox:hover {
    border: 1px solid #aaa;
} /* 마우스오버 */
.navi_border_left {
    border-left: 3px solid #0821478a;
}
.selectboxs {
    width: 100%;
    padding: 10px;
    padding-left: 12px;
    border: 1px solid #ddd;
    height: 50px;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 12px;
    color: #000;
    outline: none;
}
.selectbox:hover {
    border: 1px solid #aaa;
} /* 마우스오버 */
.project_left {
    border-right: 1px solid #bbbbbb;
}
.pro_contain {
    border: 1px solid #bbbbbb;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
}

.page_title {
    padding: 30px 0;
}
.page_title h1 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 800;
}
.search_box {
    height: 50px;
}
.search_input {
    width: 200px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    outline: unset;
    height: 50px;
    padding: 10px;
}
.savebtnwrap {
    width: calc(100% - 40px);
    position: absolute;
    bottom: 20px;
    justify-content: right;
    align-items: center;
}
.savetime {
    font-size: 12px;
    color: #00685a;
}
.savebtn {
    height: 30px;
    border: unset;
    border-radius: 10px;
    background-color: #0b255b;
    color: #fff;
    width: 70px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    display: block;
}

.absoluteBox {
    width: calc(100% - 40px);
    position: absolute !important;
    bottom: 10px;
}
.savebtn.absolute {
    position: absolute !important;
    top: 15px;
    right: 60px;
}
.savebtn.absolutes {
    position: absolute !important;
    top: 15px;
    right: 180px;
}
.searchbtn {
    height: 50px;
    border: unset;
    background-color: #0b255b;
    color: #fff;
    width: 80px;
}
/* sort box */
.sort_box {
    padding: 20px 10px;
    background-color: #f8f8f8;
}
.sort_box li {
    font-size: 15px;
    cursor: pointer;
    margin-right: 20px;
    font-weight: 500;
}
/* 마이페이지 */
.myproject_box {
    padding: 5px 0;
    margin-bottom: 10px;
}
.myproject_box h1 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}
.myproject_box p {
    font-size: 14px;
}
.interest {
    cursor: pointer;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: 1px solid #757575;
}
.interest.check {
    background-color: #0b255b;
}
.interestProject {
    background-color: #fff;
}
.client_top {
    margin-top: 20px;
}
.client_top,
.client_body {
    box-shadow: rgba(0, 0, 0, 0.03) 0px 3px 6px;
    border-radius: 10px;
    padding: 20px;
    background-color: rgb(255, 255, 255);
    margin-bottom: 28px;
}
.client_left,
.client_right {
    padding: 30px 20px;
}
.client_left {
    width: 200px;
    border-right: 1px solid #d9d9d9;
}

.client_left li {
    margin-bottom: 20px;
    cursor: pointer;
}
.client_left li a {
    color: #000;
}
.client_left li.active {
    font-weight: 700;
    font-size: 18px;
}
.circle {
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    border: 1px solid #757575;
    background-color: #d9d9d9;
    text-align: center;
    line-height: 25px;
    font-size: 14px;
    margin-right: 10px;
}
.circless {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 1px solid #000;
    background-color: #fff;
    text-align: center;
    line-height: 18px;
    font-size: 14px;
    margin-right: 10px;
}
.infobox {
    padding: 10px;
}
.infobox > h1 {
    margin-bottom: 10px;
}

.project_ban {
    padding: 20px;
    text-align: center;
    background-color: #042d52;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
}

.savebox {
    position: relative;
    width: calc(33.333% - 10px);
    padding: 20px 32px 24px;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(230, 235, 240, 0.8);
    border: solid 1px #e6ebf0;
    background-color: #fff;
}
.savebox h1 {
    padding: 10px 0;
    font-weight: 700;
}
.savecon {
    height: 180px;
    overflow-y: auto;
}
.savecon a {
    padding: 5px 0;
    display: block;
    color: #000;
    font-size: 14px;
}
.savebox p {
    color: #757575;
    font-size: 14px;
}

.pgT {
    font-size: 24px;
    font-weight: 900;
    padding: 5px 0;
    margin-bottom: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* 표시할 줄 수를 2로 제한 */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.pgdetail {
    padding-top: 20px;
    padding-left: 30px;
    padding-bottom: 40px;
    padding-right: 30px;
    width: calc(100% - 300px);
}

.pgbody > h1 {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 10px;
    color: #212121;
    font-weight: 900;
}
.pgbody .pro_box {
    padding: 20px 0;
}
.crossline {
    width: 1px;
    background-color: #757575;
    height: 16px;
}
.subcatebox span,
.subcatebox li {
    color: #757575;
}
.subcatebox {
    padding-bottom: 20px;
    border-bottom: 1px solid #757575;
    margin-bottom: 20px;
}

.pgbody {
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #757575;
}

.pgbox_top .savebtn {
    height: 100% !important;
}

.pgbody .pgboxs {
    margin-bottom: 20px;
}
.pgbody .pgboxs:last-child {
    margin-bottom: 0px;
}
.pgboxs h2 {
    color: #757575;
}

.subbox {
    margin-bottom: 10px;
    border: 1px solid #a8a8a8;
    padding: 5px;
}

.mypgbody {
    width: calc(100% - 200px);
    padding: 20px;
}
.mypghead h1 {
    font-size: 24px;
}

.important {
    font-weight: 700;
    font-size: 16px;
    display: block;
}
.confiden_box {
    padding: 20px;
}
.confiden_box h2 {
    font-weight: 700;
    font-size: 18px;
}
.confiden_box strong {
    font-size: 16px;
    padding: 5px 0;
    font-weight: 700;
}
.confiden_box p {
    font-size: 15px;
    padding: 10px 0;
}

.confi_left li {
    padding: 10px 0;
}

.confi_left li.active {
    font-weight: 900;
}

.comPany {
    font-weight: 700;
    color: #0d3a9c;
    font-size: 20px;
}
.myCompany {
    font-weight: 700;
    color: #b32b2b;
    font-size: 20px;
}
.date {
    padding: 10px 0;
    font-size: 20px;
    text-align: center;
    font-weight: 900;
}
.rightclientbox,
.clientbox {
    position: relative;

    padding-bottom: 60px;
}
.stamp {
    position: absolute;
    bottom: 0;
    width: 100px;
    height: 100px;
}
.writing {
    position: absolute;
    bottom: 40px;
    height: 50px;
    max-width: 100px;
}

.input_wrap {
    width: 100%;
}
.input_wrap h1 {
    font-weight: 700;
    font-size: 18px;
}
.out_cell {
    padding: 10px;
}
.out_cell .w35,
.out_cell .w15,
.out_cell .w25,
.out_cell .w33,
.out_cell .w20,
.out_cell .w30 {
    padding: 10px;
    border-right: 1px solid #757575;
    height: 45px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.cell_line .w15:first-child,
.cell_line .w35:first-child,
.cell_line .w25:first-child,
.cell_line .w33:first-child,
.cell_line .w20:first-child,
.cell_line .w30:first-child {
    border-left: 1px solid #757575;
}
.cell_line {
    border-bottom: 1px solid #757575;
}
.cell_head {
    font-weight: 700;
}
.out_cell .cell_line:first-child {
    border-top: 1px solid #757575;
}
.outwrap {
    padding: 20px 20px 80px;
}
.outwrap h2 {
    font-size: 18px;
    padding: 5px 0;
}
.out_detail h1 {
    font-size: 18px;
}
.out_detail strong {
    font-weight: 700;
    padding: 5px 0;
    margin-top: 10px;
    display: block;
}
.space {
    width: 10px;
}
.modalt > span:first-child span {
    display: inline-block;
}
.modalt > span > span {
    display: none;
}
.outwrap .modalt {
    font-size: 16px;
    padding-left: 20px;
}
.outwrap h3 {
    font-weight: 700;
    font-size: 20px;
    padding: 10px 0;
    margin-bottom: 20px;
}
.intitle {
    margin-top: 10px;
    padding: 10px;
    text-align: center;
    font-size: 24px;
    font-weight: 900;
}

/* 프린트 */

.report {
    break-after: page;
}

.report + .report {
    margin-top: 40px;
}

@media print {
    .report + .report {
        margin-top: 0;
    }
}

@page {
    size: A4;
    margin: 20mm;
}

.imgbox {
    width: 150px;
    height: 150px;
    padding: 10px;
}
.imgbox.active {
    background-color: #fff;
    border: 5px solid #000046;
}
.rightbtn {
    border: none;
    outline: none;
    background-color: #042d52;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    height: 40px;
    padding: 0px 20px;
    cursor: pointer;
}
.rightbtn.red {
    background-color: #b32b2b !important;
}

.importtext {
    font-size: 20px;
    font-weight: 700;
}
.imgwrap {
    height: 400px;
}
.imgwrap img {
    object-fit: contain;
}
.stampwrap {
    position: relative;
}
.stampbox {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 0px;
    top: -20px;
}
.stampTapwrap {
    border-bottom: 1px solid #757575;
}
.stampTapwrap div {
    padding: 10px;
    cursor: pointer;
}
.stampTapwrap div.active {
    font-size: 18px;
    font-weight: bold;
}
.sigWrap {
    width: 400px;
    padding: 20px;
    border: 1px solid #949494;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

.stamptextWrap h1 {
    font-size: 20px;
    font-weight: 900;
    text-align: center;
}
.stamptextWrap p {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
}
.innermbox {
    border-top: 4px solid #000046;
}
.innermbox .left {
    width: 180px;
}
.innermbox .left div {
    background-color: #c5c5c5;
    padding: 10px;
}
.innermbox .right {
    width: calc(100% - 180px);
}
.innermbox .right div {
    padding: 10px;
}

.user_top {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid #000046;
}
.userContain {
    padding: 20px;
}
.statustag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
    background-color: #000046;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 2px;
}
.userbox {
    border-bottom: 1px solid #949494;
    font-size: 14px;
    cursor: pointer;
}
.userbox:hover {
    background-color: #d4d4d4;
}
.userbox > li {
    border-right: 1px solid #949494;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.statustag.color {
    background-color: #00685a;
}
.user_desc {
    font-size: 14px;
    color: #949494;
    font-weight: 500;
    cursor: pointer;
}

.money_top {
    width: 80%;
}

.money_top h1 {
    padding: 10px 0;
    font-size: 20px;
    font-weight: 700;
}
.money_top_table {
    border-top: 4px solid #000046;
}

.money_title {
    font-weight: 900;
}

.detailuser {
    font-size: 14px;
    padding: 10px;
}
.detailuser_sub > li {
    padding: 5px;
}
.none980 {
    display: block;
}

/* 로그인 */
img {
    width: 100%;
}
.login {
    height: 100vh;
    width: 100%;
    background: radial-gradient(#1e3c72, #2a5298);
    position: relative;
    display: flex;
}
.login .login_box {
    width: 980px;
    height: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: 1px 4px 22px -8px #0004;
    display: flex;
    overflow: hidden;
}
.login_box.join {
    padding: 20px;
    margin: 0;
    height: 100%;
    background: #fff;
    box-shadow: 1px 4px 22px -8px #0004;

    overflow: hidden;
}

.login_box.join h3 {
    font-size: 24px;
    font-weight: 700;
    text-align: left;
}
.login_box.join .passerror {
    text-align: left;
}
.login_box .left {
    width: 41%;
    height: 100%;
    padding: 25px 25px;
}
.login_box .right {
    width: 59%;
    height: 100%;
    overflow: hidden;
}
.left .top_link a {
    color: #452a5a;
    font-weight: 400;
}
.left .top_link {
    height: 20px;
}
.right > img {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: -1;
}
.left .contact {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    height: 100%;
    width: 73%;
    position: relative;
    margin: auto;
}
.left h3 {
    text-align: center;
    margin-bottom: 40px;
}
.left input {
    border: none;
    margin: 15px 0px;
    border-bottom: 1px solid #4f30677d;
    padding: 7px 9px;
    width: 100%;
    overflow: hidden;
    background: transparent;
    font-weight: 600;
    font-size: 14px;
}
.companymiddle {
    height: 50%;
    justify-content: space-between;
}
.midText {
    color: #fff;
    font-weight: 500;
    font-size: 26px;
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
}
.companymiddle img {
    object-fit: cover;
}
.left input.saveId {
    width: unset;
    margin-left: 10px !important;
    cursor: pointer;
}
.left input.userId {
    width: calc(100% - 90px);
}
.left input.check {
    width: unset;
}
.left .savebtn {
    width: 80px;
}
.left {
    background: linear-gradient(-45deg, #dcd7e0, #fff);
}
.submit {
    cursor: pointer;
    border: none;
    padding: 15px 70px;
    border-radius: 8px;
    display: block;
    margin: auto;
    margin-top: 120px;
    background: #1e3c72;
    color: #fff;
    font-weight: bold;
    -webkit-box-shadow: 0px 9px 15px -11px rgba(88, 54, 114, 1);
    -moz-box-shadow: 0px 9px 15px -11px rgba(88, 54, 114, 1);
    box-shadow: 0px 9px 15px -11px rgba(88, 54, 114, 1);
}

.right {
    color: #000;
    font-weight: 700;
    position: relative;
}
.rightbg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #7c7c7c56;
}
.join {
    cursor: pointer;
    color: #616161;
    font-weight: 300;
    margin-top: 20px;
    display: block;
    font-size: 14px;
    text-align: center;
}
.right .right-text {
    height: 100%;
    position: relative;
    transform: translate(0%, 45%);
}
.right-text h2 {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 50px;
    font-weight: 500;
}
.right-text h5 {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 19px;
    font-weight: 400;
}

.right .right-inductor {
    position: absolute;
    width: 70px;
    height: 7px;
    background: #fff0;
    left: 50%;
    bottom: 70px;
    transform: translate(-50%, 0%);
}
.top_link img {
    width: 28px;
    padding-right: 7px;
    margin-top: -3px;
}

.empty {
    font-size: 36px;
    font-weight: 700;
}
.contract-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin: auto;
    padding: 10px 25px;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.slide {
    width: 100%;
    padding: 5px;
    background-color: #fff;
}

.slide-title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 5px;
}

.slide-content {
    font-size: 13px;
}

.navigation {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.navigation button {
    padding: 7px;
    border: none;
    border-radius: 5px;
    background-color: #000046;
    color: #fff;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}
.navigation button.lefts {
    left: 0;
}

.navigation button.rights {
    right: 0;
}

.navigation button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
/* 모바일 헤더 */
.mheader {
    width: 100%;
    height: 80px;
    padding-left: 10px;
    position: fixed;
    top: 0;
    z-index: 1500;
    background-color: #000;
}
.identity.before {
    font-size: 14px;
    color: #757575;
    padding: 10px 0;
}
.identity.after {
    font-size: 14px;
    color: #00685a;
    padding: 10px 0;
}
.Tmessage h1 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}
.textwrap {
    margin-bottom: 20px;
}

.priceTag {
    padding: 5px;
    border: 1px solid #1baacc;
    color: #000;
    border-radius: 5px;
    font-size: 15px;
    width: 190px;
}
/* 모달 관련 */
.modal_header2 {
    background-color: #000046;
    width: calc(100% + 20px);
    padding: 10px;
    margin-top: -20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    cursor: grab;
    margin-bottom: 20px;
}
.modal_header {
    background-color: #000046;
    width: calc(100% + 40px);
    padding: 10px;
    margin-top: -20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    cursor: grab;
    margin-bottom: 20px;
}
.modal_header > div {
    color: #fff;
}
.modal_header_in {
    width: calc(100% - 40px);
    text-align: center;
    color: #f3f3f5;
    font-weight: bold;
    font-size: 18px;
}
.modal_header_in2 {
    width: calc(100% - 40px);
    text-align: center;
    color: #f3f3f5;
    font-weight: bold;
    font-size: 20px;
}

/* 버튼 관련 */
.bigbtn {
    min-width: 300px;
    width: 50%;
    padding: 10px;
    border-radius: 10px !important;
    border: 2px solid #fff !important;
    outline: none;
    font-weight: 700;
    font-size: 30px !important;
}
.greenbtn {
    background-color: #00685a;
    font-weight: 700;
    color: #fff;
    padding: 5px;
    cursor: pointer;
    border: unset;
    font-size: 14px;
    transition: background-color 0.3s, color 0.3s;
}
.greenbtn:hover {
    background-color: #01c0a7;
}
.navibtn_hover:disabled {
    background-color: #888888;
}
.navibtn_hover:disabled:hover {
    background-color: #636363;
}
.navibtn_hover {
    text-align: center;
    height: fit-content;
    width: 182px;
    padding: 12px 24px;
    font-weight: 600;
    font-size: 18px;
    background-color: #1baacc; /* Tomato color */
    color: #fff;
    border: none;
    outline: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transform: scale(1);
}
.navibtn_hover:hover {
    background-color: #326abea6; /* Darker shade of Tomato */
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}
.navibtn_hover.absolute {
    position: absolute;
    bottom: 20px;
    width: calc(100% - 64px);
}
.ps {
    padding: 5px !important;
}
.border_white {
    border: 1px solid #fff !important;
}
.leftmiddle {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translate(-50%, 0);
}
.rightmiddle {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, 0);
}

.gbox_hover {
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 16px;
    font-weight: bold;
    background-color: #11296b;
    color: #fff;
    border-radius: 5px;
    text-align: center;

    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
}
.gbox_hover:hover {
    background-color: #798377 !important;
    transform: translateY(3px);
}

/* 마이페이지 상단 탭 관련 */
.Tabs {
    width: 100%;
    background-color: #fff;
    padding: 10px;
}

.Tabs ul {
    display: flex;
    background-color: #fff;
}
.Tabs li {
    margin-right: 10px;
}
.Tabs li a {
    display: block;
    cursor: pointer;
    font-weight: bold;
    padding: 10px;
    color: #000;
}
.Tabs li.active {
    border-bottom: 2px solid #1e3c72;
}

.ProjectTabs li {
    overflow: hidden;
    left: 0px;
    right: 0;
    width: calc((100% - 300px) / 5);
    height: 60px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    /* margin-right: 0.5%; */
    justify-content: space-between;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #333;
    background-color: #a0cbed;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
    position: relative;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}
.ProjectTabs li:first-child {
    width: 280px;
    margin-right: 20px;
}
.ProjectTabs li.active {
    background-color: #6aa7da;
}

.tag_triangle {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-bottom: 30px solid transparent;
    border-top: 30px solid transparent;
    border-left: 50px solid #6aa7da;
    border-right: 30px solid transparent;
}
.ProjectTabs li.active .tag_triangle {
    border-left: 50px solid #02022988 !important;
}

.ProjectTabs li.active a {
    color: #080808;
    font-weight: bold;
    text-shadow: unset;
}

.none {
    display: none !important;
}

.ProjectTabs li a {
    text-align: center;
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
    overflow: hidden;
    font-weight: bold;
    line-height: 48px;
    padding: 5px;
    color: #fff;
    padding-left: 30px;
    font-size: 16px;
    text-shadow: -1px 0px #000, 0px 1px #000, 1px 0px #000, 0px -1px #000;
}

.TProjectTabsabs li.active {
    border-bottom: 2px solid #1e3c72;
}
/* .widthunset{
    width: unset !important;
 } */

.table_container .table_label {
    width: 100px;
    color: #042d52;
    margin-right: 10px;
}
.table_container ul {
    border: 3px solid #042d52;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}
.table_container li {
    padding: 10px;
    flex-grow: 1;
    align-items: center;
    border-bottom: 2px solid #042d52;
}
.table_container ul li:last-child {
    border-bottom: unset;
}
.name_subtitle {
    font-weight: 700;
    font-size: 18px;
    color: #042d52;
    margin-bottom: 15px;
    text-align: center;
    padding-bottom: 5px; /* 아래쪽 여백 설정 */
}

/* 페이지네이션 */
.pagination {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}
.pgnation {
    width: 30px;
    height: 30px;
    display: block;
    text-align: center;
    cursor: pointer;
}
.pgnation.active {
    font-weight: 700;
    background-color: #042d52;
    color: #fff;
}

.tablediv {
    border-bottom: 1px solid #4b6cb7;
}

.pdfcontain {
    overflow: hidden;
    padding-top: 10px;
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}
.react-pdf__Page__svg {
    max-width: 800px !important;
    width: 100% !important;
    height: unset !important;
}
.pdfcontain svg {
    width: 100%;

    height: auto;
}
.react-pdf__Page {
    width: 100%;
}
.react-pdf__Page__canvas {
    width: 100% !important;

    height: fit-content !important;
}
.pdfcontain.mini .react-pdf__Page__canvas {
    width: 100% !important;
    height: fit-content !important;
}
/* .react-pdf__Page__canvas{
    width: 100% !important;
    height: max-content !important;
} */
.offerbtn_wrap {
    width: 100%;
}

.offerbtn_wrap.absol {
    width: calc(100% - 40px);
    position: absolute;
    bottom: 20px;
}
.contractstep_wrap .contractstep {
    width: 20%;
    text-align: center;
    padding: 5px;
}
.alertIconWrap {
    position: relative;
}
.alertIcon {
    color: #fff;
    font-weight: 900;
    cursor: pointer;
}
.alertIcontop {
    color: #fff;
    font-weight: 900;
    position: absolute;
    top: -5px;
    color: #b32b2b;
    right: -5px;
}

.ex_contain {
    background-color: #fff;
    width: calc(100% - 40px);
    position: absolute;
    top: 60px;
    z-index: 1000;
}
.messageBox {
    width: 100%;
    padding: 10px 20px;
    border-radius: 20px;
    margin-bottom: 5px;
    min-height: 80px;
    position: relative;
}
.messageBox.noticebox {
    background-color: #fffeb7;
}
.messageBox.othersbox {
    background-color: #bef4ff;
}
.messageBox.user {
    min-height: 50px !important;
    background-color: #000046;
    color: #fff;
}
.messageIcon {
    position: absolute;
    top: 10px;
    right: 10px;
}
.projectnav .messageBox:last-child {
    margin: 0;
}
.messageBox.read {
    background-color: unset;
}
.messageBox h1 {
    margin-bottom: 5px !important;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.messageBox p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: unset !important;
}
.messageBox > .messageBox_h {
    cursor: pointer;
    margin: unset;
}
.writebox {
    padding: 20px;
}
.receiveBox {
    border-radius: 20px;
    border: 4px solid #000046;
}
.recevie_top {
    padding: 30px;
    font-weight: 700;
    font-size: 18px;
    border-bottom: 4px solid #000;
}
.receiveBox > h3 {
    font-size: 17px;
    font-weight: 700;
    padding: 20px 20px 5px 20px;
}
.receiveBox > h4 {
    font-size: 15px;
    font-weight: 700;
    padding: 5px 20px;
    margin-bottom: 10px;
    color: #636161;
}
.recevie_body {
    padding: 30px;
    min-height: 30vh;
}

.step_wrap > li > span.active {
    font-weight: 700;
}
.step_wrap li {
    margin-bottom: 0px;
}
.minicircle {
    display: block;
    width: 20px;
    height: 20px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #929292;
}

.minicircle.active {
    background-color: #000046;
    color: #fff;
}

.step_title {
    font-size: 18px; /* 폰트 크기 조절 */
    color: #083768b9; /* 글자 색상 설정 */
    font-weight: 900;
}
.step_title .tapicon {
    font-size: 20px;
}
.project_img_container {
    position: relative;
    width: 100%;
    height: calc(100% - 180px);
}
.zoom_controls {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
}
.project_img {
    width: auto;
    height: 100%;
}
.project_img img {
    width: 100%;
    height: 100%;
}
.project_img img:hover {
    cursor: grab;
}
.project_img img:active {
    cursor: grabbing;
}
/* 스크롤바 css */

.scrolls::-webkit-scrollbar {
    width: 6px;
}

/* 스크롤바 막대 설정*/
.scrolls::-webkit-scrollbar-thumb {
    height: 17%;
    background-color: #1baacc;
    border-radius: 10px;
}

/* 스크롤바 뒷 배경 설정*/
.scrolls::-webkit-scrollbar-track {
    background-color: #000046;
}
.text_absolute {
    width: max-content;
    position: absolute;
    font-size: 80px;
    font-weight: 700;
    text-align: center;
    color: #a3a3a3;
    opacity: 0.6;
    top: 50%;
    z-index: 5;
    left: 50%;
    transform: translate(-50%, -50%) rotate(325deg);
}

.basic_contractbox {
    padding: 20px;
    min-height: 820px;
    max-height: 1300px;
    margin: 40px 0;
}
.basic_contractbox.mini {
    min-height: 720px;
}
.basic_contractbox.mini p {
    font-size: 14px;
}
.basic_contractbox .paper {
    position: absolute;
    left: 50%;

    width: 85%;
    min-height: 800px;
    max-height: 1100px;
    padding: 20px;
    top: 0;
}
.basic_contractbox.mini .paper {
    position: absolute;
    left: 50%;
    width: 85%;
    min-height: 720px;
    max-height: 1100px;
    padding: 20px;
    top: 0;
}
.z4 {
    z-index: 4;
    background-color: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    transform: translate(-50%, 0);
}
.z3 {
    z-index: 3;
    background: #fafafa;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

    transform: rotate(1deg) translate(-50%, 0);
}
.z2 {
    z-index: 2;
    background: #fafafa;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

    transform: rotate(1.6deg) translate(-50%, 0);
}
.z1 {
    z-index: 1;
    background: #f6f6f6;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);

    transform: rotate(-2.5deg) translate(-50%, 0);
}
.ModalMypageInfo {
    min-height: 400px;
    border-radius: 10px;
    background: white;
    overflow: hidden;
    box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075), 0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
        0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
}
.ModalMypageInfo.develop {
    box-shadow: unset !important;
}

.ModalMypageInfo > nav {
    background: #fdfdfd;
    padding: 5px 5px 0;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid #000;
    margin-bottom: 20px;
}

.ModalMypageInfo main {
    flex-grow: 1;
    user-select: none;
    max-height: 700px;
    overflow-y: scroll;
}
.ModalMypageInfo > nav > ul {
    gap: 5px;
}
.ModalMypageInfo nav li {
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: white;
    cursor: pointer;
    height: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    flex: 1;
    min-width: 0;
    position: relative;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
}
.ModalMypageInfo.develop nav li {
    color: #000;
}
.right_body_wrap_column .file-container,
.pgboxs .file-container {
    width: 100%;
    margin-bottom: 8px;
}
.file-container {
    width: calc(100% - 170px);
    padding: 5px 10px;
    border: 2.2px solid #041577;
    border-radius: 15px;
    cursor: pointer;
    transition: border-color 0.3s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ModalMypageInfo nav li.selected {
    background: #1baacc;
    color: #fff;
    font-weight: 700;
    border-bottom: 3px solid #000046;
}
.registration-container {
    display: flex;
    padding: 0px;
    justify-content: space-around;
    align-items: center;
}

.vertical-flow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.step-box {
    display: flex;
    flex-direction: column;
    border: 2px solid #000046;
    border-radius: 5px;
    text-align: center;
    width: 200px;
    height: 80px;
    margin: 10px;
    position: relative;
    white-space: pre-line;
    font-weight: 600;
    align-items: center;
    justify-content: center;
}

.mt200 {
    margin-top: 160px;
}
.step-wrap {
    display: flex;
    position: relative;
}
.mt50 {
    margin-top: 100px !important;
}
.w650 {
    max-width: 650px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.arrow_tip {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid black;
    transform: rotate(270deg); /* 180도 회전시킴 */
    margin-top: 0px;
    margin-right: -10px;
}

.boxSpecial {
    width: 200px;
    height: 50px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #000046; /* 배경색상 */
    border-radius: 15px; /* 모서리 둥글게 만들기 */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 그림자 효과 */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff; /* 글자색상 */
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1px; /* 글자 간격 */

    /* 그라데이션 효과 추가 */
}

.boxSpecial_ab {
    position: absolute;
    top: -70px;
    left: 45%;
    transform: translate(-50%);
    width: 200px;
    height: 50px;
    margin: 10px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    background-color: #000046; /* 배경색상 */
    border-radius: 15px; /* 모서리 둥글게 만들기 */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 그림자 효과 */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff; /* 글자색상 */
}
.connecting-line {
    height: 2px;
    width: 200px;
    background-color: black;
}
.listwrap {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 150px);
}
.listwrap .list:first-child .comma {
    display: none;
}
.textCenter {
    text-align: center;
}

@media screen and (max-width: 1520px) {
    .w1480 {
        margin: 0 20px;
        width: calc(100% - 40px);
    }
}
@media screen and (max-width: 1240px) {
    .subnav_box.ml370 div:first-child {
        margin-left: 310px;
    }
    .ProjectTabs li a {
        font-size: 14px;
    }
    .ModalMypageInfo-1 nav li {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .roadtitle {
        font-size: 18px;
    }
    .grid-item {
        padding: 10px;
        width: 50%;
    }
    .grid-item h2 {
        font-size: 14px;
    }
    .grid-item p {
        font-size: 14px;
    }

    .list-group {
        width: 100%;
    }
    .navibtn_hover.absolute {
        width: calc(100% - 40px);

        padding: 10px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
    }
    .w33 {
        width: calc(50% - 10px) !important;
    }
    .triple .right_body_wrap:last-child {
        width: 100% !important;
        margin-top: 40px;
    }
    .out_cell .w35,
    .out_cell .w15,
    .out_cell .w25,
    .out_cell .w33 {
        font-size: 14px;
        padding: 5px;
    }
    .logo {
        width: 120px;
        height: 40px;
    }
    .header {
        padding: 20px;
    }
    .headbg {
        top: 90px;
    }
    .navi > li {
        font-size: 14px;
        width: unset;
    }

    .right_top {
        padding: 20px;
        font-size: 20px;
    }
    .right_top_s {
        font-size: 18px;
        padding-bottom: 20px;
    }
    .right_body,
    .right_bottom {
        padding: 20px;
    }
    .right_body_wrap h1,
    .add_left h1 {
        font-size: 16px;
    }
    .list-group-control label {
        font-size: 16px;
        line-height: 16px;
    }
    .list-group-control {
        padding: 5px;
    }
    .add_left {
        width: 200px;
    }
    .add_left_projectlistbox {
        width: 280px !important;
    }
    .add_right_wrap {
        width: calc(100% - 310px);
    }
    .savebox {
        padding: 15px 20px 15px;
    }
    .projectbtn {
        left: 20px;
        width: calc(100% - 40px);
    }
    .userContain {
        width: 1200px;
        padding: 0;
    }
    .userContain_wrap {
        overflow-y: scroll;
        padding: 20px;
        -ms-overflow-style: none; /* 인터넷 익스플로러 */
        scrollbar-width: none; /* 파이어폭스 */
    }
    .userContain_wrap::-webkit-scrollbar {
        display: none;
    }
    .subnav_box div {
        width: 25%;
    }
    .subnav_box div a {
        min-width: 100%;
    }
}

@media screen and (max-width: 980px) {
    .info-cell span {
        width: 70px;
        margin-right: 10px;
    }
    .info-cell span,
    .info-cell p,
    .info-cell div {
        font-size: 14px;
    }
    .info-cell p,
    .info-cell div {
        width: calc(100% - 75px);
    }

    .portfolio.scrollbox .w90_ {
        width: 100% !important;
    }
    .project_wrap.p20 {
        padding: 0;
    }
    /* .project_img {
        height: calc(70vh - 230px);
    } */
    .project_img {
        overflow: scroll;
    }
    .project_box {
        margin-right: 0px;
        width: 100%;
        height: 390px;
        margin-bottom: 20px;
    }
    .info-row {
        display: block !important;
    }
    .bl0 {
        border-left: 1px solid #ddd !important;
    }
    .br0 {
        border-right: 1px solid #ddd !important;
    }
    .info-cell {
        width: 100% !important;
    }
    .pdf_border-box {
        font-size: 12px !important;
        padding: 5px;
        width: 50px !important;
    }
    .next_btn_hover,
    .pdfDownloadBTN svg {
        font-size: 12px !important;
    }
    .quote-container {
        padding: 10px;
    }
    .next_btn_hover,
    .pdfDownloadBTN {
        width: 40px !important;
    }
    .footRight {
        flex-direction: column;
    }
    .horizontal-eastimate-bar {
        top: 50px !important;
    }
    .estimatebox li {
        font-size: 14px;
    }
    .footRight li {
        width: 100%;
        text-align: right;
    }

    .footRight li:first-child {
        display: none;
    }
    .est-dev-contract {
        font-size: 12px !important;
        height: 28px !important;
        padding-left: 5px;
        padding-right: 5px;
        margin: 0 !important;
    }
    .text_absolute {
        font-size: 40px;
    }
    .mainbackT p.normal {
        margin-top: 0px;
    }
    .est-dev-contract {
        width: 80px;
    }
    .btnTop {
        align-items: center;
        margin-right: 0px !important;
    }
    .pdfTop .mr10 {
        margin-right: 0px !important;
    }
    .next_btn_hover,
    .pdfDownloadBTN {
        width: 50px;
        font-size: 14px;
        margin: unset;
        padding: 5px !important;
    }

    .pdf_border-box {
        width: 70px;
        font-size: 14px;
        margin: unset !important;
        padding: 5px !important;
    }
    .outwrap {
        padding: 10px 10px 40px;
    }
    .p2020 {
        padding: 10px;
    }
    .confiden_box {
        padding: 0;
    }
    .right_body {
        padding: 10px;
    }
    .Tmessage h1 {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .navibtn_hover.absolute {
        width: calc(100% - 20px);
        font-size: 14px;
    }
    .textwrap p {
        font-size: 14px;
    }
    .savebox h1 {
        font-size: 14px;
    }
    .minititle {
        display: block;
    }
    .projectAdd_inputbox::placeholder {
        font-size: 14px;
    }
    .w1480 {
        margin: 0 10px;
        width: calc(100% - 20px);
    }
    .projectInfo-container .mb40,
    .projectInfo-container .mb80 {
        margin-bottom: 10px;
    }
    .projectInfo-container {
        padding: 10px;
    }
    .list-group li {
        width: 33.333%;
        padding: 5px 0px;
    }
    .lilabel,
    .radiolabel {
        font-size: 13px;
    }
    .add_left_projectlistbox h1 {
        font-size: 18px !important;
    }
    .contain {
        min-height: unset;
    }
    .offerbtn_wrap.absol {
        top: 10px;
        right: 10px;
        height: fit-content;
        width: fit-content;
        bottom: 20px;
    }
    .add_left_projectlistbox h1 {
        font-size: 14px !important;
    }
    .offerbtn_wrap.absol .w33 {
        width: 50px !important;
        margin-left: 2px;
    }
    .footer ul {
        flex-wrap: wrap;
        margin-right: 5px;
    }
    .radiolabel label {
        display: flex;
        align-items: center;
    }
    .subnav_box div {
        margin-right: 5px;
    }
    .subnav_box.ml370 {
        justify-content: center !important;
    }
    .minicate li:last-child {
        width: 100%;
    }
    .triple .right_body_wrap:last-child {
        margin-top: 10px !important;
    }
    .roladMap-box {
        width: 100%;
        padding: 10px;
        height: 90vh;
    }
    .companyT p.big {
        font-size: 20px;
    }
    .companyT {
        margin-left: 10px;
    }
    .midText {
        font-size: 16px;
        padding: 10px;
    }

    .divbox {
        margin-top: 200px;
    }
    .companyT p {
        font-size: 16px;
    }
    .companyT p.big span {
        font-size: 22px;
    }
    .mainbackT p.big {
        font-size: 20px;
        line-height: 20px;
    }
    .mainbackT p.middle span {
        margin-right: 10px;
    }
    .maintechinbox p {
        font-size: 13px;
    }
    .mainbackT p.normal {
        font-size: 25px;
        line-height: 30px;
    }
    .mainbackmiddle h5 {
        font-size: 20px;
    }
    .mainbackT.subTbottom {
        top: 210px;
    }
    .mainbackT.br {
        right: 20px;
        bottom: 60px;
    }
    .maintechbox {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        width: 100%;
        height: calc(33.33333% - 5px);
        min-height: 130px;
        border-left: unset;
        border-right: unset !important;
        border-bottom: 1px solid #757575;
    }
    .maintechinbox {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: unset;
        padding: 10px 0;
    }
    .maintechinbox > img {
        width: 90px;
        height: 100%;
        margin-right: 10px !important;
        margin-bottom: unset;
    }
    .maintechinbox h1 {
        font-size: 16px;
        font-weight: 900;
    }
    .bigbtn {
        font-size: 20px !important;
    }
    .imgmain {
        width: 100%;
        height: auto;
        top: 65%;
        transform: translate(-50%, -50%);
    }
    .maintech {
        width: 100%;
        display: flex;
        flex-direction: column;
        top: 230px;
    }
    .maintechinbox h2,
    .maintechinbox h1 {
        font-size: 16px;
    }
    .w33 {
        width: 100% !important;
    }
    .mainbackT p {
        font-size: 22px;
    }
    .mainbackT.subT {
        top: 100px;
    }

    .mainbackT p.middle {
        font-size: 16px;
        display: flex;
        flex-direction: column;
    }
    .mainbackT p.middle span.w {
        font-size: 25px;
    }
    .add_left_projectlistbox {
        height: 120px !important;
        margin-bottom: 10px;
        padding: 10px;
    }
    .right_top_project_infowrite_Title {
        margin-top: 0px;
    }
    .textLeft {
        text-align: left;
    }
    .projectnavss.scrollss {
        height: 200px;
    }
    .navibtn_hover {
        width: 120px;
        font-size: 14px;
        padding: 5px;
    }
    .savebtnwrap {
        width: unset;
        height: fit-content;
        top: 10px;
        right: 20px;
    }
    .subnav_box.ml370 div:first-child {
        margin-left: unset;
    }
    .add_left_projectlistbox {
        width: 100% !important;
    }
    .subnav_box {
        justify-content: center;
        width: 100%;
    }
    .subnav_box div a {
        padding: 5px 0;
        width: 100%;
        font-size: 13px;
    }
    .subnav_box div {
        width: calc(33.333% - 5px);
        font-size: 14px;
    }
    .none980 {
        display: none;
    }
    .savebox {
        padding: 10px;
        width: calc(50% - 5px);
    }

    .subheadimg,
    .subheadText {
        display: none;
    }
    .subhead {
        height: 40px;
        margin-top: 68px;
    }
    .addproject {
        display: block;
    }
    .projectnav {
        width: 100%;
        flex-direction: unset !important;
        overflow-x: auto;
        white-space: nowrap;
        justify-content: unset !important;
    }
    .projectnav > p {
        margin-right: 10px;
        font-size: 14px;
        width: 130px;
    }
    .add_left {
        width: 100%;
        height: 60px;
        display: flex;
    }
    .add_left > h1 {
        display: none;
    }
    .add_right_wrap {
        width: 100%;
        overflow: hidden;
    }
    .project {
        min-height: calc(100vh - 240px);
    }

    .add_right_wrap {
        margin-top: 20px;
    }
    .project_left {
        display: none;
    }
    .project_right {
        width: 100%;
    }
    .login .login_box {
        width: 90%;
    }
    .login_box .left {
        width: 100%;
    }
    .right {
        display: none;
    }
    .add_left.fixed {
        position: fixed;
        top: 80px;
        left: 0px;
        z-index: 1000;
    }
    .footer .logo {
        width: 80px;
        height: 25px;
    }
    .footerboxTop li a {
        font-size: 13px;
        font-weight: 500;
        color: #fff;
    }
    .footerbox li {
        font-size: 12px;
    }
    .footerbox ul:first-child {
        width: 60% !important;
    }

    .footer {
        height: 130px;
    }
    .mnavi .btnstyle {
        text-align: left !important;
        color: #000 !important;
    }
    .mainbackmiddle .mt50 {
        margin-top: 20px !important;
    }
    .mainbackmiddle h5 {
        font-size: 16px;
    }
    .maintech {
        height: calc(100vh - 380px);
    }
    .footer .p20tb {
        padding: 10px 0px;
    }
    .footer .mr20 {
        margin-right: 5px;
    }
    .save_wrap {
        display: block;
    }
    .project_ban {
        margin-bottom: 10px;
        font-size: 16px;
        padding: 10px;
    }
    .save_wrap .savebox {
        width: 100%;
        height: 160px;
        margin-bottom: 10px;
    }
    .confiden_box h1 {
        font-size: 14px;
    }
    .confiden_box p {
        font-size: 12px;
    }
    .confiden_box ol li {
        font-size: 12px;
    }
    .step-box {
        width: 110px;
        height: 100px;
        margin: 5px;
        font-size: 14px;
    }
    .boxSpecial,
    .boxSpecial_ab {
        width: 110px;
        font-size: 14px;
        margin: 5px;
    }
    .connecting-line {
        width: 40px;
    }
    .add_left_projectlistbox_1 h1 {
        font-size: 14px !important;
    }
    .add_left_projectlistbox_1 {
        width: 100% !important;
    }
    .signaturebox h3 {
        font-size: 16px !important;
    }
    .comPany,
    .myCompany {
        font-size: 15px !important;
    }
    .out_detail h1 {
        font-size: 15px;
    }
    .outwrap h2 {
        font-size: 14px;
    }
    .signaturebox .bottombox {
        display: block;
    }
    .signaturebox .bottombox > div {
        width: 100% !important;
    }
    .clientbox p,
    .rightclientbox p {
        font-size: 14px;
    }
    .stampbox {
        width: 70px;
        height: 70px;
    }
    .money_top {
        width: 100%;
    }
    .out_cell {
        padding: 5px;
    }
    .estimatebox > div {
        display: block !important;
    }
    .estimatebox > div > div {
        width: 100% !important;
    }
    .estimatebox > div > div.pr {
        border-right: unset;
        margin-bottom: 10px;
    }
    .estiHead,
    .esti_head {
        font-size: 15px;
    }
    .estimatebox li {
        padding: 5px;
    }

    .portfolio.scrolls {
        overflow: unset;
    }
    .project_img img {
        width: 100%;
        height: 100%;
        transition: transform 0.2s ease-in-out;
        transform-origin: top left;
    }
    .project_img_container {
        overflow: hidden;
        height: calc(100% - 230px);
    }
    .zoom_controls {
        display: block;
    }
    .modal_header_in2 {
        font-size: 16px;
    }
}

@media screen and (max-width: 720px) {
    .portfolio.scrollbox.flex.ac {
        align-items: unset !important;
    }
}
