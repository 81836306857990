* {
    box-sizing: border-box;
}
.list-group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.list-group ul {
    width: 100%;
    display: flex;
}
.list-group ul > li {
    width: inherit;
}
.list-group li {
    width: 20%;
    padding: 10px;
}
.minicate li:last-child {
    width: 60%;
}

.cate li:last-child {
    width: 100%;
}
.cate li:last-child label,
.minicate li:last-child label {
    display: flex;
    align-items: center;
    width: 100%;
}
.list-group-control:last-child .otherinput {
    display: unset;
}
.list-group-control .otherinput {
    display: none;
}
.list-group-control {
    padding: 5px;
}
.list-group-control label {
    font-size: 15px;
    line-height: 15px;
}

[type='radio'] {
    appearance: none;
    border: max(2px, 0.1em) solid gray;
    border-radius: 50%;
    width: 1.2em;
    height: 1.2em;
    transition: border 0.2s ease-in-out;
}
/* [type="checkbox"] {
    appearance: none;
    border: max(2px, 0.1em) solid gray;

    width: 1.2em;
    height: 1.2em;
    transition: border 0.2s ease-in-out;
  }
  [type="checkbox"]:checked {
    border: 0.65em solid #0b255b;
  } */
[type='radio']:checked {
    border: 0.65em solid #0b255b;
}
.otherinput {
    font-size: 16px;
    padding: 12px;
    border: none;
    border-radius: 30px;
    background-color: #f0f0f0;
    margin-left: 10px;
    outline: none;
    width: calc(100% - 70px);
    display: inline-block;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
