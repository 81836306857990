/*정대현 추가 Title.프로젝트 정보 작성부분 */
.right_top_project_infowrite_Title {
    padding: 10px 20px;
    font-size: 28px;
    font-weight: bold;
    font-family: Arial, sans-serif;
    background: linear-gradient(135deg, #060142, #93d3ec);
    color: transparent;
    text-align: left;
    margin-top: 30px;
    line-height: 1.5;
    position: relative;
    overflow: hidden;
    cursor: default;
    border-radius: 10px;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out, background 0.5s ease-in-out; /* background 속성에 애니메이션 적용 */
    animation: fadeInText 1.5s ease-in-out forwards, slideInText 1.5s ease-in-out forwards;
}

.right_top_project_infowrite_Title::before {
    top: -5px;
    left: -5px;
    border-top: 1px solid #0f0;
    border-left: 1px solid #0f0;
}

@keyframes fadeInText {
    to {
        color: white;
    }
}

@keyframes slideInText {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes neonBorderBlink {
    0%,
    100% {
        border-color: #a6cdee;
        box-shadow: 0 0 5px rgba(15, 19, 230, 0.1);
    }
    50% {
        border-color: #065304;
        box-shadow: 0 0 50px rgba(1, 33, 82, 0.5);
    }
}

.right_top_project_infowrite_Title:hover {
    transform: scale(1.03);
    opacity: 0.9;
    background: linear-gradient(135deg, #081041, #eeec89); /* 호버 시 배경색상 변경 */
    color: white; /* 호버 시 텍스트 색상 변경 */
    background-size: 200% 100%; /* 배경 크기 두 배로 확장 */
    background-position: right; /* 배경 위치를 오른쪽으로 이동 */
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out, background 0.5s ease-in-out,
        color 0.5s ease-in-out; /* background 속성에 애니메이션 적용 */
}

.right_top_project_infowrite_Title:hover::before {
    left: 0;
}

/* 프로젝트 정보작성 사각 테두리 및 타이틀 부분 */
.projectInfo-container {
    position: relative;
    border: 8px solid #041577;
    border-radius: 10px;
    padding: 20px;
    background-color: #f8f8f8;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
    overflow: hidden;
    transition: border-color 0.3s, transform 0.3s, opacity 0.3s; /* opacity 속성에도 transition 추가 */
}

.projectInfo-container::before,
.projectInfo-container::after {
    right: -5px;
    bottom: -5px;
    border-bottom: 1px solid rgba(214, 240, 67, 0.7); /* 투명한 선 설정 */
    border-right: 1px solid rgba(180, 228, 49, 0.7); /* 투명한 선 설정 */
}

.projectInfo-container::before {
    width: calc(100% + 9px);
    height: calc(100% + 9px);
}

.projectInfo-container::after {
    width: calc(100% + 11px);
    height: calc(100% + 11px);
}

.projectInfo-container:hover {
    border-color: #4d92c0; /* 호버 시 테두리 색 변경 */
    transform: scale(1.05); /* 애니메이션에 의한 확대 효과 추가 */
}

.projectInfo-container:hover::before,
.projectInfo-container:hover::after {
    background-color: #3498db; /* 호버 시 선 색 변경 */
}

/*edit 창*/
.editprojectInfo-container {
    position: relative;
    border: 3.5px solid #07125c; /* 테두리 두께 수정 */
    border-radius: 10px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
    overflow: hidden;
    transition: border-color 0.3s ease-in-out, transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* opacity 속성에도 transition 추가 */
}

.editprojectInfo-container:hover {
    border-color: #9cc7f8; /* 호버 시 테두리 색상 변경 */
    opacity: 1; /* 호버 시 불투명도 조절 */
}

/*프로젝트 등록 미리보기 모달창*/
/* 기본 테두리 스타일 */

/* 파일 인풋 숨기기 */

/* 호버 효과 - 드래그 & 드롭 */
.file-container:hover {
    border-color: #3498db; /* 변경할 색상 */
}

/* 드래그 & 드롭 텍스트 스타일 */

/* 삭제 버튼 스타일 */
.delete-button {
    text-align: center; /* 텍스트 가운데 정렬 */
    background-color: #041577; /* 배경색 변경 */
    height: 38px;
    color: #fff;
    border: none; /* 테두리 제거 */
    border-radius: 5px; /* 더 작고 둥글게 만듦 */
    padding: 10px; /* 적당한 여백 설정 */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 14px; /* 폰트 크기 변경 */
    margin-left: 15px; /* 버튼 사이 여백 추가 */
}
.delete-button:hover {
    background-color: #3498db; /* 호버 효과 */
}

/*select box*/
/* 화려한 select box 스타일 */
.selectboxs_style {
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 10px solid #c7d4e0;
    border-radius: 2px;
    border-left: 10px solid #c7d4e0;
    background: transparent;
    outline: none;
    font-size: 16px;
    color: #333;
    transition: border-bottom 0.3s, transform 0.3s;
    cursor: pointer;
}

/* 화면에 hover 효과 */
.selectboxs_style:hover {
    border-bottom-color: #6c757d;
}

/* select box 옵션 스타일 */
.selectboxs_style option {
    background-color: #fff;
    color: #333;
    font-size: 16px;
    padding: 10px;
}

/* select box 드롭다운 스타일 */
.selectboxs_style select::-ms-expand {
    display: none;
}

/* select box 화살표 아이콘 */
.selectboxs_style::after {
    content: '\25BC'; /* Unicode character for down arrow */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}

/* 스타일 돈글자 관련*/
.custom-price {
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: #333;
    margin: 10px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 4px solid #6c757d;
    padding-bottom: 5px;
    display: inline-block;
}

/* 원 스타일 */

/* 스크롤바 스타일 */
.projectnavss.scrollss {
    max-height: 500px; /* 스크롤 박스의 최대 높이 설정 */
    overflow-y: scroll; /* 수직 스크롤바 표시 */
    overflow-x: hidden; /* 수평 스크롤바 숨김 */
    padding: 10px;
    position: relative; /* 상대적 위치 지정 */

    /* 스크롤바 내부 스타일 */
    &::-webkit-scrollbar {
        width: 10px; /* 스크롤바 너비 설정 */
        height: 10px; /* 스크롤바 높이 설정 */
    }

    &::-webkit-scrollbar-thumb {
        background: #7f8fa6; /* 스크롤바 썸 색상 */
        border-radius: 10px; /* 스크롤바 썸의 모양 설정 */
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #57606f; /* 스크롤바 썸에 호버 시 색상 변경 */
    }

    &::-webkit-scrollbar-track {
        background: #dcdde1; /* 스크롤바 트랙 색상 */
    }

    &::-webkit-scrollbar-track:hover {
        background: #b8c3d3; /* 스크롤바 트랙에 호버 시 색상 변경 */
    }

    /* 스크롤바를 왼쪽에 배치 */
    scrollbar-width: thin; /* 스크롤바 두께 설정 (커스터마이즈 가능) */
    scrollbar-color: #7f8fa6 #dcdde1; /* 스크롤바 색상 설정 (커스터마이즈 가능) */
}

.projectnavss .projectboxing {
    cursor: pointer;
    padding: 5px;
    margin-bottom: 10px;
    background: rgba(66, 63, 62, 0.863);
    color: #fff;
    text-align: left;
    border-radius: 8px;
    font-weight: 700;
    transition: background 0.3s, transform 0.2s;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    position: relative;
}
.projectnavss .projectboxing.active {
    background: rgba(44, 73, 126, 0.788);
    /*transform: scale(1.03);*/
}

.projectnavss .projectboxing > div {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 표시할 줄 수를 2으로 제한 */
    -webkit-box-orient: vertical;
    padding: 10px 0;
    margin: 5px;
    max-height: 4em; /* 세 줄 텍스트의 최대 높이 설정 (3줄 * 줄 높이) */
    line-height: 1.6em; /* 일관된 간격을 위해 줄 높이 설정 */
    white-space: normal;
}

.projectnavss .projectboxing > span {
    padding: 10px 0;
    display: flex;
}

.projectnavss .projectboxing::before {
    font-size: 20px; /* 원 모양 크기 조정 */
    color: rgb(12, 5, 49); /* 기본 원 모양 색상 설정 */
    position: absolute; /* 절대 위치 지정 */
    left: -25px; /* 왼쪽 위치 조정 */
    top: 50%; /* 수직 가운데 정렬 */
    transform: translateY(-50%); /* 수직 가운데 정렬을 위한 변형 */
}

/* 아이콘 크기 조정 */
.lightbulb-icon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-left: 10px;
    background-color: #464542;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    transition: background-color 0.3s ease, transform 0.3s ease;
}

/* 클릭된 경우의 색상 변경 */
.projectboxing.active .lightbulb-icon {
    background-color: rgba(194, 231, 134, 0.986);
}

.dotted_boxs {
    position: absolute;
    right: 10px;
    border: 2px solid #33333370;
    background-color: #fdfdfd; /* 채우기 색상 설정 */
    border-radius: 5px;
    padding: 5px;
    font-size: 10px;
    color: #070707; /* 글자색 설정 */
}

.n-shape {
    position: relative;
    width: auto;
    height: 30px;
    margin-left: 10px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
}

.vertical-bar {
    position: absolute;
    background-color: #3333333f;
    width: 5px;
    height: 30px;
    top: 0;
    left: 0;
}

.horizontal-bar {
    position: absolute;
    background-color: #3333333f;
    width: 180px;
    height: 5px;
    bottom: 0;
    left: 5px;
}

.complete-box {
    position: absolute;
    left: 165px;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
    background-color: #33333370;
    color: #fff;
    border-radius: 5px;
    text-align: center;
}
.proceeding-box {
    position: absolute;
    left: 165px;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
    background-color: rgba(39, 114, 185, 0.747);
    color: #fff;
    border-radius: 5px;
    text-align: center;
}

.fontStyle-active {
    position: relative;
    font-size: 16px;
    font-weight: bolder;
    color: rgba(39, 114, 185, 0.747);
}

.fontStyle-complete {
    position: relative;
    font-size: 16px;
    color: rgb(8, 8, 8);
}

.retouch-box {
    display: inline-block;
    padding: 5px 30px;
    margin-top: 20px;
    margin-left: 10px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    color: #fff;
    background-color: rgba(39, 114, 185, 0.747);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s, transform 0.2s;
    border: none;
    outline: none;
    cursor: pointer;
}

.retouch-box:hover {
    background-color: #ff6f42;
    transform: scale(1.05);
}

.fontStyle-day {
    position: relative;
    font-size: 13px;
    color: rgb(90, 44, 30);
}

.add_left_projectlistbox {
    width: 340px;
    position: relative;
    max-height: calc(100vh - 380px);
    padding: 20px;
    background-color: #fff;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
}

.add_left_projectlistbox.fixed {
    position: fixed;
    top: 110px;
    left: 0px;
    height: inherit;
}
.add_left_projectlistbox h1 {
    margin-bottom: 10px;
    font-weight: 900;
    font-size: 22px;
}

.add_left_projectlistbox_1 {
    width: 340px;
    position: relative;
    max-height: calc(100vh - 100px);
    padding: 20px;
    background-color: #fff;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
}

.add_left_projectlistbox_1.fixed {
    position: fixed;
    top: 110px;
    left: 0px;
    height: inherit;
}
.add_left_projectlistbox_1 h1 {
    margin-bottom: 10px;
    font-weight: 900;
    font-size: 22px;
}

.rd10 {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.horizontal-eastimate-bar {
    z-index: 100;
    position: absolute;
    background-color: #000046;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 5px;
}
.t80 {
    top: 80px;
}
.t140 {
    top: 160px;
}

.pdfDownloadBTN {
    background-color: #1baacc;
    border: none;
    color: white;
    margin-right: -10px;
    width: 117px;
    padding: 9px;
    position: relative;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.pdfDownloadBTN:hover {
    background-color: #326abea6;
}

.custom-btn {
    margin-left: 30px;
    margin-right: -10px;
    margin-top: -15px;
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: bolder;
    font-size: 14px;
    border: 2px;
    background-color: rgba(44, 73, 126, 0.795);
    font-family: 'Lato', sans-serif;
    color: #fcfdfd;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
    display: inline-block;
    outline: none; /* Remove outline on click */

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-btn:hover {
    background: #07568b;
    color: #fff;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.custom-btn:active {
    background: #07568b;
    color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    transform: translateY(2px);
}
.w_round_tag_c {
    position: relative;
}

.w_round_tag_c > li {
    position: relative;
    width: 75px;
    height: 25px;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 10.2px;
    border: 1px solid #3e424734;
    color: #000;
    text-align: center;
    background-color: #fff;
    padding: 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    text-overflow: ellipsis;
    overflow: hidden; /* 넘친 텍스트를 감출 때 사용 */
    white-space: nowrap;
}
.w_round_tag_cateBox {
    position: relative;
    width: 80px;
    height: 25px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 2px;
    font-size: 10.2px;
    border: 1px solid #3e424734;
    border-radius: 5px;
    color: #000;
    text-align: center;
    background-color: #fff;
    padding: 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.next_btn_hover {
    text-align: center;
    height: fit-content;
    padding: 12px 24px;
    width: 115px;
    font-weight: 600;
    font-size: 16px;
    background-color: #1baacc; /* Tomato color */
    color: #fff;
    border: none;
    outline: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transform: scale(1);
}
.next_btn_hover:hover {
    background-color: #326abea6; /* Darker shade of Tomato */
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}
.next_btn_hover.absolute {
    position: absolute;
    bottom: 20px;
    width: calc(100% - 64px);
}
.est-dev-contract {
    position: relative;
    height: 41px;
    width: 135px;
    background-color: rgba(44, 73, 126, 0.788);
    border-radius: 5px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    font-size: 20px;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.pdf_border-box {
    width: auto; /* 너비를 조절 */
    height: auto; /* 높이를 조절 */
    border: 2px solid rgba(44, 73, 126, 0.788);
    padding: 5px;
    border-radius: 8px;
    margin-bottom: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 12.5px;
}

.add_left_Myproject {
    width: 280px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
    max-height: calc(100vh - 150px);
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
}

/* subhead - outsorcing전용*/
.subhead_ot {
    width: 100%;
    height: 400px;
    position: relative;
    overflow: hidden;
    margin-bottom: 0px;
}
.subhead_ot .subheadimg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.subheadT1 {
    font-size: 30px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
}
.subheadTb2 {
    text-align: center;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
}

.ModalMypageInfo-1 {
    min-height: 400px;
    border-radius: 10px;
    background: white;
    position: relative;
    overflow: hidden;
    box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075), 0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
        0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
}
.ModalMypageInfo-1.develop {
    box-shadow: unset !important;
}

.ModalMypageInfo-1 > nav {
    background: #fdfdfd;
    padding: 5px 5px 0;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 2px solid #041577;
    margin-bottom: 20px;
    margin-top: 10px;
}

.ModalMypageInfo-1 main {
    flex-grow: 1;
    user-select: none;
    max-height: 700px;
    overflow-y: scroll;
}
.ModalMypageInfo-1 > nav > ul {
    gap: 5px;
}
.ModalMypageInfo-1 nav li {
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: white;
    cursor: pointer;
    height: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    flex: 1;
    min-width: 0;
    position: relative;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
}
.ModalMypageInfo-1.develop nav li {
    color: #000000;
}

.ModalMypageInfo-1 nav li.selected {
    background: #1baacc;
    color: #fff;
    font-weight: 700;
}
.p10-1 {
    padding: 0px !important;
    margin: 0px;
}
.textCenter {
    text-align: center;
}
.iconSize {
    margin-right: 5px;
    font-size: 16px;
}
.horizontal-line {
    width: 100%; /* 가로로 전체 너비를 차지하도록 설정 */
    height: 1px; /* 줄의 높이를 1px로 설정 (설정할 높이에 따라 조절 가능) */
    background-color: rgb(26, 46, 134); /* 줄의 색상을 원하는 색으로 설정 */
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: -15px;
}
.fontWb {
    font-weight: 700;
}

.filebtn_hover {
    position: relative;
    text-align: center;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 18px;
    background-color: #1baacc; /* Tomato color */
    color: #fff;
    border: none;
    outline: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.posionA {
    position: relative;
    left: 0;
}

.clientInfo_title {
    font-family: 'Arial, sans-serif';
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    margin-bottom: 10px; /* Adjust the margin as needed */
}

.myStyledModalText {
    background-color: lightblue;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 20px;
}

.tables {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

.rows {
    display: flex;
}

.cells {
    flex: 1;
    padding: 5px;
    height: 100px;
    border: 2.5px solid #3498db; /* Add border styling */
    border-radius: 8px;
    color: #3498db; /* Text color matching border color */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent; /* Set background color to transparent */
}

/* App.css */

.grid-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.tableT h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 5px;
}
#map {
    width: 100%;
    height: calc(100% - 300px);
}
.grid-item {
    flex: 1;
    width: 20%;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
}

.grid-item h2 {
    color: #3498db;
    margin-bottom: 10px;
    position: relative;
}

.grid-item h2::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 1px;
    background-color: #02071a;
}

.grid-item p {
    color: #555;
}

.roadtitle {
    color: #3498db;
    font-family: 'Arial', sans-serif;
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.title-separator {
    height: 2px;
    width: 100%;
    background-color: #3498db;
    margin: 10px auto;
}

.roladMap-box {
    width: 90%;
    height: 900px;
    border: 2px solid #3498db; /* 추가: 테두리 색상 지정 */
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 20px;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
