.containers {
    background-color: #dadada;
    height: 400px;
}

.mainconwrap {
    width: 100%;

    height: calc(100% - 150px);
}
.main-image {
    height: 100%;
    width: 100%;
}

.sidebar {
    padding: 10px;
}

.sidebar-item {
    cursor: pointer;
    border: none;
    outline: none;
    padding: 1rem;
    position: relative;
    transition: background-color 150ms;
}

.sidebar-item:hover,
.sidebar-item:focus {
    font-weight: 700;
}

.sidebar-item.active {
    font-weight: 700;
}

.sidebar-item::after {
    content: '';
    position: absolute;
    width: 80%;
    left: 10%;
    bottom: 0;
    height: 1px;
}

.sidebar-item:last-child::after {
    display: none;
}

.slider-container {
    margin-top: 20px;
    padding: 10px;
}

.slider {
    width: 100%;
    cursor: pointer;
}
